import {qsa} from "./global";

const pageNavLinks = qsa(".sub-menu-header__inner-items a"); // links
const elements = qsa(".trigger-sub-nav-active-class"); // section class - to trigger active section

const FIXED_NAV_OFFSET = 120; // the height of both sticky bars - header and sub menu

const visiblePixels = function (el) {
	const rect = el.getBoundingClientRect();

	const elementHeight = rect.bottom - rect.top;

	if (
		rect.top >= FIXED_NAV_OFFSET &&
		rect.bottom > window.innerHeight &&
		rect.top < window.innerHeight
	) {
		//  the top of the element is on the screen, but the bottom of the div is not.
		const visible = window.innerHeight - rect.top;
		return visible;
	}
	if (rect.top > FIXED_NAV_OFFSET && rect.bottom < window.innerHeight) {
		//  the whole div is on the screen
		const visible = elementHeight;
		return visible;
	}
	if (rect.top < FIXED_NAV_OFFSET && rect.bottom > window.innerHeight) {
		// the top is above the top of the screen, and the bottom is below, so it takes up the whole screen
		const visible = window.innerHeight;
		return visible;
	}
	if (
		rect.top < FIXED_NAV_OFFSET &&
		rect.bottom < window.innerHeight &&
		rect.bottom > FIXED_NAV_OFFSET
	) {
		//  the top is off the screen, and the bottom is on screen.
		const visible = rect.bottom;
		return visible;
	}
	if (rect.top > window.innerHeight || rect.bottom < FIXED_NAV_OFFSET) {
		//  do nothing, the element is not on the screen yet, or has already been scrolled past.
		return 0;
	}
	return 0;
};

const ifIsVisible = function () {

	let mostVisibleElementHeight = 0;
	let mostVisibleElement = "";
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < elements.length; i++) {
		const visPixels = visiblePixels(elements[i]);
		if (visPixels > mostVisibleElementHeight) {
			mostVisibleElementHeight = visPixels;
			mostVisibleElement = elements[i];

			qsa(`.sub-menu-header__inner-items a.active`).forEach((link) => {
				link.classList.remove("active");
			});
		}
	}
	//  apply the styling
	const elID = mostVisibleElement.id;

	// find matching link & add appropriate class
	const activeSubNavLink = document.querySelector(
		`.sub-menu-header__inner-items a[data-target="${elID}"]`
	);
	if (activeSubNavLink) {
		activeSubNavLink.classList.add("active");
	}
};

export default function pageNavScroller() {
	pageNavLinks.forEach((el) => {
		el.addEventListener("click", (event) => {
			event.preventDefault();

			if (el.dataset.target) {
				const targetID = el.dataset.target;
				const target = document.querySelector(`#${targetID}`);
				const elementSpace = target.getBoundingClientRect();
				const currentPosition = document.documentElement.scrollTop;

				const offsetPosition = elementSpace.top + currentPosition - FIXED_NAV_OFFSET;

				window.scrollTo({
					behavior: "smooth",
					top: offsetPosition
				});
			} else {
				return;
			}
		});
	});

	window.addEventListener("scroll", ifIsVisible);
}

import Flickity from "flickity";
import 'flickity-imagesloaded';
import {qsa} from "./global";

const imageSlidersTarget = qsa(".phase2__slider-module");

Flickity.prototype._createResizeClass = function() {
	setTimeout(() => {
		this.element.classList.add('flickity-resize');
	});
};

Flickity.createMethods.push('_createResizeClass');

const resize = Flickity.prototype.resize;

Flickity.prototype.resize = function() {
	const theElement = this.element;

	theElement.classList.remove('flickity-resize');
	resize.call( this );
	theElement.classList.add('flickity-resize');
};

function initFlickity(el, options, previousButton, nextButton) {
	const imagesFlickity = new Flickity(el, options);

	previousButton.classList.add("visible");
	nextButton.classList.add("visible");

	previousButton.addEventListener("click", () => {
		imagesFlickity.previous();
	});
	nextButton.addEventListener("click", () => {
		imagesFlickity.next();
	});
}
export default function carousel() {

    imageSlidersTarget.forEach((el) => {
        const slides = el.querySelector(".pod-carousel");

	    const previousButton = el.querySelector(".pod-carousel__prev-button");
	    const nextButton = el.querySelector(".pod-carousel__next-button");

        // make all the slides the same height
        const podSlides = el.querySelectorAll(".pod-carousel__slide");
        const slidesLength = podSlides.length;

        if (slidesLength < 4 && matchMedia("screen and (min-width: 960px)").matches) {
	        const existingCarousel = Flickity.data('.pod-carousel');
	        if( typeof existingCarousel !== "undefined") {
		        existingCarousel.destroy();
		        previousButton.classList.remove("visible");
		        nextButton.classList.remove("visible");
	        }
        } else {
            const options = {
                containScroll: false,
                wrapAround: true,
                prevNextButtons: false,
                pageDots: false,
                freeScroll: true,
                contain: true,
                cellAlign: "left",
                imagesLoaded: true,
            };

            if (matchMedia("screen and (max-width: 768px)").matches) {
                options.cellAlign = "center";
            }
            initFlickity(slides, options, previousButton, nextButton);


        }
    });
}

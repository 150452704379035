import carousel from "./phase2/carousel";
import {floorplanTabs,floorplanTables} from "./phase2/floorplan";
import throttle from 'lodash/throttle';
import pageNavScroller from "./phase2/pageScroll";

document.documentElement.classList.remove('no-js');

floorplanTabs();
carousel();
pageNavScroller();

if (matchMedia("screen and (max-width: 768px)").matches) {
	floorplanTables();
}

window.addEventListener('resize', throttle(() => {
	const viewportWidth = window.innerWidth;
	if (viewportWidth < 769) {
		floorplanTables();
	}
},200));

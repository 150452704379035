import {qsa} from "./global";

const floorPlanTabs = qsa(".floorplans__tab");
const changeColumnSelects = document.querySelectorAll('.changeColumn');

const CLASSES = {
	hidden: 'hidden',
	visible: 'visible',
	floorplanButtonCurrent: 'floorplans__tab--current',
	floorplanContent: 'floorplans__tab-content',
	floorplanContentCurrent: 'floorplans__tab-content--current',
}

function hideShowTableCells(array, newIndex) {
	array.forEach((cell) => {
		const index = Number(cell.dataset.index);
		newIndex = Number(newIndex); // just in-case it wasn't an integer
		if (index !== 0 && index !== 1 && index !== newIndex) {
			cell.classList.add(CLASSES.hidden);
		} else {
			cell.classList.remove(CLASSES.hidden);
		}
	})
}

function setActiveFloorplanTab(newTab, currentBlock) {
	// make the tab active, and remove active from all others
	const blockTabs = currentBlock.querySelectorAll('.floorplans__tab-content');
	blockTabs.forEach((blockTab) => {
		if (Number(blockTab.dataset.tabcontent) === newTab) {
			blockTab.classList.add(CLASSES.floorplanContentCurrent);
		} else {
			blockTab.classList.remove(CLASSES.floorplanContentCurrent);
		}
	})
}

function setActiveFloorplanButton(newTab, currentBlock) {
	// get all the buttons
	const blockButtons = currentBlock.querySelectorAll('.floorplans__tab');
	blockButtons.forEach((blockButton) => {
		if(Number(blockButton.dataset.tab) === newTab) {
			// add current class to the tab that we want to be active
			blockButton.classList.add(CLASSES.floorplanButtonCurrent);
		} else {
			// remove the current class from them
			blockButton.classList.remove(CLASSES.floorplanButtonCurrent);
		}

	})

	setActiveFloorplanTab(newTab, currentBlock);
}

function handlerTabInteraction(el) {
	// what tab are we asking for? We'll make sure it's an integer for continuity
	const newTab = Number(el.dataset.tab);
	if (!Number.isInteger(newTab) || newTab < 1) {
		return;
	}
	// restrict all further work to the current block only
	const currentBlock = el.closest('.floorplans');
	setActiveFloorplanButton(newTab, currentBlock);
}

export function floorplanTabs() {
	floorPlanTabs.forEach((el) => {
		el.addEventListener('click', () => {
			handlerTabInteraction(el);
		});
	});
}
export function floorplanTables() {
// we make the tables more mobile friendly
	const floorPlanTables = qsa('.floorplans__tab-text table');
	floorPlanTables.forEach((el) => {
		const tableCells = el.querySelectorAll('td');
		const tableHeaders = el.querySelectorAll('th');
		hideShowTableCells(tableCells, 2);
		hideShowTableCells(tableHeaders, 2);
	});
	// we are making the select visible here incase the user has javascript turned off, in which case the select would be useless

	changeColumnSelects.forEach((changeColumnSelect) => {
		// changeColumnSelect.classList.add(CLASSES.visible);
		changeColumnSelect.addEventListener('input', function (event) {
			const newColumn = event.target.value;
			const currentSection = changeColumnSelect.closest('.floorplans__tab-text');
			const currentTableCells = currentSection.querySelectorAll('table td');
			const currentTableHeaders = currentSection.querySelectorAll('table th');
			hideShowTableCells(currentTableCells, newColumn);
			hideShowTableCells(currentTableHeaders, newColumn);
		});
	})
}
